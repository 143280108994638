import React from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import { Helmet } from 'react-helmet'
import { colors } from '@/theme'
import { motion } from 'framer-motion'
import { useInView } from 'react-intersection-observer'
import { Grid } from '@material-ui/core'
import { Nav, NavProps } from './nav'

const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
  }
  body {
    font-family: 'Nunito', sans-serif;
    font-size: 62.5%;
    color: ${colors.textPrimary};
    position: relative;
  }
  h1 {
    font-size: 3.7em;
    font-weight: 800;
    letter-spacing: normal;
  }
  h2 {
    font-size: 3.7em;
    font-weight: 700;
    letter-spacing: normal;
  }
  h3 {
    font-size: 2.7em;
    font-weight: 700;
    letter-spacing: normal;
  }
  li{
    color: ${colors.textPrimary};
    padding-bottom: 0.8em;
  }
  p {
    font-size: 1.6em;
    font-weight: 400;
    line-height: 2.5em;
    margin-bottom: 1.5em;
    color: ${colors.textSecondary}
  }
  #___gatsby {
    min-height: 100vh;
    max-width:100vw;
    overflow: hidden;
  }

`
const Footer = styled(motion.footer)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 24px 24px 48px 0px;
  border-top: 1px solid rgb(100, 100, 100);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
`
const Main = styled(motion.main)`
  padding-bottom: 32px;
`
interface PageLayoutProps extends NavProps {
  children: JSX.Element | JSX.Element[]
}
export const PageLayout: React.FunctionComponent<PageLayoutProps> = (props) => {
  const { ref, inView } = useInView({ threshold: 0.5 })
  const footerAnimate = inView ? { opacity: 1 } : { opacity: 0 }
  const { children, ...navProps } = props
  const footerRef = React.useRef<HTMLDivElement | null>(null)
  const [footerHeight, setFooterHeight] = React.useState(113)
  React.useEffect(() => {
    const updateFooterHeight = () => {
      if (footerRef.current) {
        setFooterHeight(footerRef.current.getBoundingClientRect().height)
      }
    }
    updateFooterHeight()
    if (window) window.addEventListener(`resize`, updateFooterHeight)
    return () => {
      if (window) window.removeEventListener(`resize`, updateFooterHeight)
    }
  }, [footerRef.current])
  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: `en`,
        }}
      >
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=Nunito&display=swap"
          rel="stylesheet"
        />
        <title>
          Tengable | Virtual Workspace | Enhancing Culture For Digital Teams
        </title>
        <meta
          name="description"
          content="Tengable is a virtual workspace bringing digital teams together. We
          incorporate collaboration and communication tools to enhance culture
          and productivity."
        />
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=UA-173759439-1"
        />
      </Helmet>

      <GlobalStyle />
      <Nav {...navProps} />
      <Main
      // initial={{ opacity: 0 }}
      // animate={{ opacity: 1 }}
      // transition={{ duration: 1 }}
      >
        {children}
      </Main>
      <div ref={ref} style={{ height: footerHeight }} />
      <Footer ref={footerRef} animate={footerAnimate}>
        <Grid container>
          <Grid item xs={12}>
            <p style={{ textAlign: `center` }}>
              Copyright 2020 Tengable LLC. All rights reserved.{` `}
            </p>
          </Grid>
        </Grid>
      </Footer>

      {/* <script src="https://apps.elfsight.com/p/platform.js" defer />
      <div className="elfsight-app-52b1dedd-aabc-48c8-9fac-8a7becbe9630" /> */}
    </>
  )
}
