import React from 'react'
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'
import { breakpoints, colors } from '@/theme'
import HamburgerMenu from 'react-hamburger-menu'
import { AnimatePresence, motion } from 'framer-motion'

const Container = styled.div`
  max-width: 128em;
  margin: 0 auto;
`
const Root = styled.div`
  position: sticky;
  top: 0;
  background-color: white;
  margin: 0;
  padding: 8px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.69);
  z-index: 10;
`
const ContentContainer = styled.nav`
  display: flex;
  align-items: center;
  flex-direction: row;
  margin: 0 auto;
  max-width: 128em;
  padding: 0;
`
const Logo = styled.div`
  padding-right: 1.6em;
  flex: 0;
`
const Title = styled.h2`
  flex: 1;
  text-align: center;
`
const LinksListMedium = styled.ul`
  display: flex;
  flex-direction: row;
  flex: 0;
  list-style: none;
  @media (max-width: ${breakpoints.md}) {
    display: none;
  }
`

const LinksListSmall = styled(motion.ul)`
  position: absolute;
  right: 0.8em;
  min-width: 20em;
  text-align: right;
  list-style: none;
  background: white;
  padding: 0.8em;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;

  & li {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    & :last-child {
      border-bottom: 0;
    }
  }
`

const LinksContainerSmall = styled.div`
  @media (min-width: ${breakpoints.md}) {
    display: none;
  }
`
const LinkItem = styled.li`
  padding: 0 0.8em;
  font-size: 1.6em;
  min-width: 6em;
`
const Link = styled.a`
  padding: 1.2em 0;
  display: block;
  text-decoration: none;
  white-space: nowrap;
  text-align: center;
  color: ${colors.textPrimary};
  &:hover {
    color: ${colors.primary};
    font-weight: 600;
  }
`

const ActiveLink = styled(Link)`
  color: ${colors.secondary};
  font-weight: 600;
  text-align: center;
`
const HamburgerMenuContainer = styled.button`
  border: 0;
  background-color: transparent;
  padding: 1.6em;
  &:focus {
    outline: 0;
    background-color: rgba(0, 0, 0, 0.1);
  }
`
const MenuCloser = styled(motion.div)`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.1);
`
export interface NavProps {
  pathname: string
  title?: string
}
const MaybeActiveLink = ({
  active,
  ...restProps
}: { active: boolean } & Omit<
  React.DetailedHTMLProps<
    React.AnchorHTMLAttributes<HTMLAnchorElement>,
    HTMLAnchorElement
  >,
  'ref'
>) => {
  const [ssrBreak, setSSRBreak] = React.useState(`no-window`)
  React.useEffect(() => {
    const sInt = setInterval(() => {
      if (window) {
        setSSRBreak(`window`)
        clearInterval(sInt)
      }
    }, 20)
    return () => clearInterval(sInt)
  }, [])
  if (active) {
    return <ActiveLink key={ssrBreak} {...restProps} />
  }
  return <Link key={ssrBreak} {...restProps} />
}
const NavLinks = ({ pathname }: { pathname: string }) => {
  const path = pathname

  return (
    <>
      <LinkItem>
        <MaybeActiveLink href="/" active={path === `/`}>
          Hire Us
        </MaybeActiveLink>
      </LinkItem>
      <LinkItem>
        <MaybeActiveLink href="/virtual-office" active={path === `/virtual-office/`}>
          Virtual Office
        </MaybeActiveLink>
      </LinkItem>
      {/* <LinkItem>
        <Link href="/features" active={path === `/features` ? 'active' : ''}>
          Features
        </Link>
      </LinkItem> */}
      {/* <LinkItem>
        <MaybeActiveLink href="/pricing" active={path === `/pricing/`}>
          Pricing
        </MaybeActiveLink>
      </LinkItem>
      <LinkItem>
        <MaybeActiveLink href="/downloads" active={path === `/downloads/`}>
          Downloads
        </MaybeActiveLink>
      </LinkItem> */}
      <LinkItem>
        <MaybeActiveLink href="/contact" active={path === `/contact/`}>
          Contact Us
        </MaybeActiveLink>
      </LinkItem>
    </>
  )
}

export const Nav: React.FunctionComponent<NavProps> = (props) => {
  const { title, pathname } = props
  const [mobileNavOpen, setMobileNavOpen] = React.useState(false)
  React.useEffect(() => {
    function disableIfOpen() {
      if (mobileNavOpen) setMobileNavOpen(false)
    }
    function escFunction(event: KeyboardEvent) {
      if (event.key === `Escape`) {
        disableIfOpen()
      }
    }

    document.addEventListener(`keydown`, escFunction)
    document.addEventListener(`scroll`, disableIfOpen)
    return () => document.removeEventListener(`keydown`, escFunction, false)
  }, [mobileNavOpen])
  return (
    <Root>
      <Container>
        <ContentContainer>
          <Logo>
            <a href="/">
              <StaticImage
                placeholder="blurred"
                src="./../img/tengable.svg"
                alt="Tengable Logo"
                layout="fixed"
                width={96}
                height={50}
              />
            </a>
          </Logo>
          <Title>{title}</Title>
          <LinksListMedium>
            <NavLinks pathname={pathname} />
          </LinksListMedium>
          <LinksContainerSmall>
            <HamburgerMenuContainer
              onClick={() => setMobileNavOpen(!mobileNavOpen)}
            >
              <HamburgerMenu
                isOpen={mobileNavOpen}
                menuClicked={() => setMobileNavOpen(!mobileNavOpen)}
                width={20}
                height={20}
                strokeWidth={1}
                rotate={0}
                color="black"
                borderRadius={0}
                animationDuration={0.5}
              />
            </HamburgerMenuContainer>
            <AnimatePresence>
              {mobileNavOpen && (
                <>
                  <MenuCloser
                    onClick={() => setMobileNavOpen(false)}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                  />
                  <LinksListSmall
                    initial={{ opacity: 0, right: -300 }}
                    animate={{ opacity: 1, right: 8 }}
                    exit={{ opacity: 0, top: 250 }}
                  >
                    <NavLinks pathname={pathname} />
                  </LinksListSmall>
                </>
              )}
            </AnimatePresence>
          </LinksContainerSmall>
        </ContentContainer>
      </Container>
    </Root>
  )
}
