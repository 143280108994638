export const colors = {
  primary: `#9e1f94`,
  secondary: `#fb5c3b`,
  textPrimary: `#403f52`,
  textSecondary: `#6f7581`,
  textLight: `#fff`,
}
export const breakpoints = {
  xs: `0px`,
  sm: `600px`,
  md: `960px`,
  lg: `1280px`,
  xl: `1920px`,
}
export const theme = {
  breakpoints,
  colors,
}
